import { PropsWithChildren } from "react";
import { Navigate, Route, Routes } from "react-router";
import ErrorImage from "../../assets/error.svg";
import { useRatePlanIdParam } from "../../hooks/useRouterParam";
import { HBK, useRatePlan } from "../../http/dashboardApi";
import i18n from "../../i18n";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import Icon from "../../ui/Icon";
import ShortInfo from "../../ui/ShortInfo";
import SvgWarning from "../../ui/icon/Warning.svg?react";
import { getFirstAvailableTranslation } from "../../utils";
import Description from "./Description";
import Photos from "./Photos";
import PoliciesAndRules from "./PoliciesAndRules";
import styles from "./RatePlan.module.css";
import Settings from "./Settings";

interface Props {
  languages: HBK.Language[];
}

const RatePlan = ({ languages }: Props) => {
  const { data: ratePlan, error, mutate } = useRatePlan(useRatePlanIdParam());

  if (error) {
    return (
      <EmptyPage
        title="Nicht gefunden"
        image={ErrorImage}
        backLinkText={i18n.navigation.backToOverview}
      >
        Der gesuchte Ratenplan konnte leider nicht gefunden werden.
      </EmptyPage>
    );
  }

  if (!ratePlan) return null;

  const hasIncompleteConfiguration = Object.values(
    ratePlan.configuration_status,
  ).some((v) => v);

  return (
    <Page
      title={getFirstAvailableTranslation(ratePlan.translations.title)}
      backLinkText={i18n.navigation.backToOverview}
      description={
        hasIncompleteConfiguration ? (
          <Warning heading="Konfiguration unvollständig">
            Um den Ratenplan zu aktivieren, müssen alle erforderlichen
            Informationen angegeben werden.
          </Warning>
        ) : undefined
      }
      shortInfo={
        <ShortInfo title="PMS-Ratenplan">
          {ratePlan.channel_title?.de && `${ratePlan.channel_title.de} - `}
          {ratePlan.channel_code}
        </ShortInfo>
      }
      tabs={[
        { to: "../settings", text: "Einstellungen" },
        {
          to: "../policies-and-rules",
          text: "Bedingungen und Regeln",
          showWarning:
            ratePlan.configuration_status.is_missing_reservation_policy,
        },
        {
          to: "../description",
          text: "Beschreibung",
          showWarning:
            ratePlan.configuration_status.is_missing_default_translations,
        },
        { to: "../photos", text: "Fotos" },
      ]}
    >
      <Routes>
        <Route
          path="settings"
          element={<Settings settings={ratePlan.settings} />}
        />
        <Route
          path="policies-and-rules"
          element={
            <PoliciesAndRules
              ratePlan={ratePlan}
              onSubmit={(rp) => mutate(rp, { revalidate: false })}
            />
          }
        />
        <Route
          path="description"
          element={
            <Description
              translations={ratePlan.translations}
              languages={languages}
              onSubmit={(rp) => mutate(rp, { revalidate: false })}
            />
          }
        />
        <Route path="photos" element={<Photos />} />
        <Route path="*" element={<Navigate to="settings" replace />} />
      </Routes>
    </Page>
  );
};

type WarningProps = PropsWithChildren<{ heading: string }>;

const Warning = ({ heading, children }: WarningProps) => (
  <div className={styles.warning}>
    <Icon glyph={SvgWarning} className={styles.warningIcon} />
    <div>
      <div className={styles.warningHeading}>{heading}</div>
      {children}
    </div>
  </div>
);

export default RatePlan;
