import useToggle from "../../hooks/useToggle";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import { Amenity } from "./Amenity";
import { Icons } from "./AmenityIcons";

interface Props {
  amenities: HBK.Amenity[];
  onSubmit: () => void;
}

const SyncLTSAmenitiesModal = ({ amenities, onSubmit }: Props) => {
  const modal = useToggle();
  const amenityCount = amenities.length;
  if (!amenityCount) return null;

  return (
    <>
      <p>
        {i18n.unsyncedAmenities(
          <Button
            layout="text"
            buttonProps={{
              onClick: modal.open,
            }}
          >
            {amenityCount} {i18n.amenity(amenityCount)}
          </Button>,
          amenityCount,
        )}
      </p>
      {modal.isOpen && (
        <Modal
          title="LTS-Ausstattungsmerkmale"
          submitText="Übertragen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          description={i18n.unsyncedAmenities(
            `${amenityCount} ${i18n.amenity(amenityCount)}`,
            amenityCount,
          )}
          onSubmit={() => {
            modal.close();
            onSubmit();
          }}
        >
          <div>
            {amenities.map((a) => (
              <Amenity key={a.id} title={a.title} glyph={Icons[a.id]} />
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default SyncLTSAmenitiesModal;
