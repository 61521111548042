import usePermissions from "../hooks/usePermissions";
import { HBK } from "../http/dashboardApi";
import Nav from "../ui/Nav";
import SvgAdminPanelSettings from "../ui/icon/AdminPanelSettings.svg?react";
import SvgBed from "../ui/icon/Bed.svg?react";
import SvgCalendar from "../ui/icon/Calendar.svg?react";
import SvgCloudSync from "../ui/icon/CloudSync.svg?react";
import SvgHouse from "../ui/icon/House.svg?react";
import SvgManageAccounts from "../ui/icon/ManageAccounts.svg?react";
import SvgPolicy from "../ui/icon/Policy.svg?react";
import SvgQuote from "../ui/icon/Quote.svg?react";
import SvgSettings from "../ui/icon/Settings.svg?react";
import SvgWidget from "../ui/icon/Widget.svg?react";
import Sidebar from "./Sidebar";

const PropertySidebar = () => {
  const isAdmin = usePermissions(HBK.Permission.Admin);

  return (
    <Sidebar
      footer={{
        linkProps: { to: "/admin" },
        glyph: SvgAdminPanelSettings,
        text: "Administration",
        hide: !isAdmin,
      }}
    >
      <Nav>
        <Nav.Item text="Unterkunft" to="../details" icon={SvgHouse} />
        <Nav.Item text="Zimmergruppen" to="../room-types" icon={SvgBed} />
        <Nav.Item text="Kalender" to="../calendar" icon={SvgCalendar} />
        <Nav.Item text="Ratenpläne" to="../rate-plans" icon={SvgQuote} />
        <Nav.Item
          text="Bedingungen und Regeln"
          to="../policies-and-rules"
          icon={SvgPolicy}
        />
        <Nav.Item text="Widgets" to="../widgets" icon={SvgWidget} />
        <Nav.Item text="Channels" to="../channels" icon={SvgCloudSync} />
        <Nav.Item
          text="Benutzerverwaltung"
          to="../user-management"
          icon={SvgManageAccounts}
        />
        {usePermissions(HBK.Permission.Admin) && (
          <Nav.Item text="Einstellungen" to="../settings" icon={SvgSettings}>
            <Nav.Item text="Portale" to="portals" />
            <Nav.Item text="Metasearch" to="metasearch" />
            <Nav.Item text="LTS" to="lts" />
            <Nav.Item text="TrustYou" to="trust-you" />
          </Nav.Item>
        )}
      </Nav>
    </Sidebar>
  );
};

export default PropertySidebar;
