import { UseFormReturn } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import FormFieldTranslations from "../../ui/FormFieldTranslations";
import Input from "../../ui/Input";
import Radio from "../../ui/Radio";
import Textarea from "../../ui/Textarea";
import { defaultLanguage } from "../../utils";

type Props = UseFormReturn<HBK.RoomTypeBody> & {
  languages: HBK.Language[];
  initialCategory: HBK.RoomCategory;
};

const RoomTypeFormFields = ({
  register,
  getValues,
  formState: { errors },
  languages,
  initialCategory,
}: Props) => (
  <>
    <FormField label="Name">
      {({ labelId }) => (
        <FormFieldTranslations
          labelId={labelId}
          languages={languages}
          errors={errors.name}
        >
          {({ labelId, language, isInvalid }) => (
            <Input
              id={labelId}
              type="text"
              {...register(`name.${language}`, {
                required: language === defaultLanguage,
              })}
              isInvalid={isInvalid}
            />
          )}
        </FormFieldTranslations>
      )}
    </FormField>
    <FormField
      label="Code"
      helpText="Eindeutiger Code der Zimmergruppe."
      error={errors.code}
    >
      {({ labelId, required, isInvalid }) => (
        <Input
          id={labelId}
          type="text"
          {...register("code", {
            required,
          })}
          isInvalid={isInvalid}
        />
      )}
    </FormField>
    <FormField label="Kategorie" error={errors.category}>
      {({ required, isInvalid }) =>
        HBK.roomCategories.map((c) => (
          <Radio
            key={c}
            label={i18n.roomType.category[c]}
            {...register("category", {
              required,
            })}
            defaultChecked={initialCategory === c}
            value={c}
            isInvalid={isInvalid}
          />
        ))
      }
    </FormField>
    <FormField
      label="Beschreibung"
      helpText="Beschreiben Sie wie das Zimmer aussieht, damit die Gäste wissen was sie erwartet."
    >
      {({ labelId }) => (
        <FormFieldTranslations
          languages={languages}
          errors={errors.description}
          labelId={labelId}
        >
          {({ language, isInvalid, labelId }) => (
            <Textarea
              id={labelId}
              rows={6}
              isInvalid={isInvalid}
              {...register(`description.${language}`, {
                required: language === defaultLanguage,
              })}
            />
          )}
        </FormFieldTranslations>
      )}
    </FormField>
    <FormField label="Minimale Belegung" error={errors.occupancy?.min}>
      {({ labelId, isInvalid, required }) => (
        <Input
          id={labelId}
          type="number"
          placeholder="2"
          {...register("occupancy.min", {
            min: 1,
            required,
            valueAsNumber: true,
          })}
          isInvalid={isInvalid}
        />
      )}
    </FormField>
    <FormField label="Standardbelegung" error={errors.occupancy?.default}>
      {({ labelId, isInvalid, required }) => (
        <Input
          id={labelId}
          type="number"
          placeholder="2"
          {...register("occupancy.default", {
            min: 1,
            required,
            valueAsNumber: true,
            validate: (value) =>
              value >= getValues("occupancy.min") ||
              i18n.errors.defaultOccupancyMin,
          })}
          isInvalid={isInvalid}
        />
      )}
    </FormField>
    <FormField label="Maximale Belegung" error={errors.occupancy?.max}>
      {({ labelId, isInvalid, required }) => (
        <Input
          id={labelId}
          type="number"
          placeholder="4"
          {...register("occupancy.max", {
            min: 1,
            required,
            valueAsNumber: true,
            validate: (value) =>
              value >= getValues("occupancy.default") ||
              i18n.errors.maxOccupancyMin,
          })}
          isInvalid={isInvalid}
        />
      )}
    </FormField>
    <FormField label="Wohnfläche in m²" error={errors.size}>
      {({ labelId, isInvalid, required }) => (
        <Input
          id={labelId}
          type="number"
          placeholder="45"
          {...register("size", {
            min: 0,
            max: 999,
            required,
            valueAsNumber: true,
          })}
          isInvalid={isInvalid}
        />
      )}
    </FormField>
  </>
);

export default RoomTypeFormFields;
