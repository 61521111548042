import classNames from "classnames";
import { ReactNode, useId } from "react";
import { FieldError } from "react-hook-form";
import FormField from "./FormField";
import styles from "./InlineFormField.module.css";

interface Props {
  label: string;
  text?: string;
  helpText?: ReactNode;
  className?: string;
  isOptional?: boolean;
  disabled?: boolean;
  alignItemsRight?: boolean;
  children:
    | ReactNode
    | ((props: {
        labelId: string;
        disabled: boolean;
        required: boolean;
        isInvalid: boolean;
      }) => ReactNode);
  error?: FieldError | undefined;
}

const InlineFormField = ({
  label,
  text,
  helpText,
  className,
  isOptional = false,
  disabled = false,
  alignItemsRight,
  children,
  error,
}: Props) => {
  const labelId = useId();
  const isInvalid = error !== undefined;

  return (
    <div className={className}>
      <label
        htmlFor={labelId}
        className={classNames(styles.field, {
          [styles.alignRight]: alignItemsRight,
        })}
      >
        {label}
        {typeof children === "function"
          ? children({
              labelId,
              disabled,
              required: !isOptional,
              isInvalid,
            })
          : children}
        {text}
      </label>
      {helpText && <FormField.Help>{helpText}</FormField.Help>}
    </div>
  );
};

export default InlineFormField;
