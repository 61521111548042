import { use } from "react";
import { Link, useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import {
  deleteReservationPolicy,
  postPropertyReservationPolicy,
  usePropertyReservationPolicies,
} from "../../http/dashboardApi";
import { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import Panel from "../../ui/Panel";
import Table from "../../ui/Table";
import SvgContentCopy from "../../ui/icon/ContentCopy.svg?react";

const ReservationPolicies = () => {
  const propertyId = usePropertyIdParam();
  const filters = useListFilters();
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);

  const { data, isValidating, mutate } = usePropertyReservationPolicies(
    propertyId,
    filters.state,
  );
  const reservationPolicies = data?.reservation_policies ?? [];
  const total = data?.total ?? 0;
  const maxPoliciesReached = total >= 5;

  const createPolicyTooltip = maxPoliciesReached
    ? "Sie haben bereits die maximale Anzahl an Reservierungsbedingungen erreicht."
    : undefined;

  return (
    <Panel
      title="Reservierungsbedingungen"
      description="Sie haben die Möglichkeit, bis zu fünf Reservierungsbedingungen zu erstellen."
      controls={
        <AddButton
          buttonProps={{
            onClick: () => navigate("create"),
            disabled: maxPoliciesReached,
            title: createPolicyTooltip,
          }}
        >
          Neue Reservierungsbedingung
        </AddButton>
      }
    >
      <Table
        head={
          <tr>
            <th>Name</th>
            <th>Angeld</th>
            <th>Verknüpfte Ratenpläne</th>
            <th />
          </tr>
        }
        isValidating={isValidating}
        total={total}
        filters={filters}
        body={() =>
          reservationPolicies.map((r) => {
            return (
              <tr key={r.id}>
                <td>
                  <Link to={r.id}>{r.name}</Link>
                </td>
                <td>{r.deposit_percentage ?? 0}&nbsp;%</td>
                <td>0</td>
                <td>
                  <Confirm
                    title="Reservierungsbedingung löschen"
                    description={
                      <p>
                        Möchten Sie die Reservierungsbedingung wirklich löschen?
                      </p>
                    }
                    onConfirm={() =>
                      deleteReservationPolicy(r.id).then(() =>
                        mutate(
                          {
                            reservation_policies: reservationPolicies.filter(
                              ({ id }) => id !== r.id,
                            ),
                            total: total - 1,
                          },
                          { revalidate: false },
                        ),
                      )
                    }
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link to={r.id}>Zeige Details</Controls.Link>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Item
                            disabled={maxPoliciesReached}
                            title={createPolicyTooltip}
                            glyph={SvgContentCopy}
                            onClick={() =>
                              postPropertyReservationPolicy(propertyId, {
                                ...r,
                                name: `${r.name} - Kopie`,
                              })
                                .then((res) => {
                                  mutate(
                                    {
                                      reservation_policies: [
                                        ...reservationPolicies,
                                        res,
                                      ],
                                      total: total + 1,
                                    },
                                    { revalidate: false },
                                  );
                                  dispatch(
                                    showSuccessNotification(
                                      "Die Reservierungsbedingung wurde erfolgreich dupliziert.",
                                    ),
                                  );
                                })
                                .catch((error: unknown) =>
                                  dispatch(showErrorNotification(error)),
                                )
                            }
                          >
                            Duplizieren
                          </Controls.Item>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Delete onClick={open} />
                        </Controls.Group>
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          })
        }
      />
    </Panel>
  );
};

export default ReservationPolicies;
