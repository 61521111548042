import { useFieldArray } from "react-hook-form";
import { SWRResponse } from "swr";
import useToggle from "../hooks/useToggle";
import { Searchable } from "../types";
import { AddButton } from "./Button";
import Combobox from "./Combobox";
import Modal from "./Modal";
import styles from "./ModalAssign.module.css";
import Selected from "./Selected";

interface Props<T> {
  title: string;
  description: string;
  fetcher: (s: Searchable) => SWRResponse<T[]>;
  onSubmit: (selected: T[]) => void;
  displayValue: (value: T) => string;
}

interface FieldArrayItem<T> {
  item: T;
}

const ModalAssign = <T extends object>({
  title,
  description,
  fetcher,
  displayValue,
  onSubmit,
}: Props<T>) => {
  const modal = useToggle();

  return (
    <>
      <AddButton
        buttonProps={{
          onClick: modal.open,
        }}
      >
        {title}
      </AddButton>
      {modal.isOpen && (
        <Modal<{ items: FieldArrayItem<T>[] }>
          title={title}
          defaultValues={{
            items: [],
          }}
          description={description}
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={({ items }) => {
            onSubmit(items.map((field) => field.item));
            modal.close();
          }}
        >
          {({ control }) => {
            const { fields, append, remove } = useFieldArray({
              control,
              name: "items",
            });

            return (
              <>
                <Combobox.Async<T>
                  fetcher={fetcher}
                  displayValue={displayValue}
                  onChange={(item) => item && append({ item })}
                />
                {fields.length > 0 && (
                  <div className={styles.selectedItems}>
                    {fields.map((field, index) => (
                      <Selected.Item
                        key={field.id}
                        name={displayValue(field.item)}
                        onDelete={() => remove(index)}
                      />
                    ))}
                  </div>
                )}
              </>
            );
          }}
        </Modal>
      )}
    </>
  );
};

export default ModalAssign;
