import { use } from "react";
import { Navigate, Route, Routes } from "react-router";
import ErrorImage from "../../assets/error.svg";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePortalParam } from "../../hooks/useRouterParam";
import { Admin, patchPortal, usePortal } from "../../http/adminApi";
import i18n from "../../i18n";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import ShortInfo from "../../ui/ShortInfo";
import Textarea from "../../ui/Textarea";
import PortalGroups from "./PortalGroups";
import PortalProperties from "./PortalProperties";
import PortalWidgets from "./PortalWidgets";

const PortalSettings = () => {
  const dispatch = use(NotificationContext);
  const { data: portal, mutate, error } = usePortal(usePortalParam());

  if (error) {
    return (
      <EmptyPage
        title="Nicht gefunden"
        image={ErrorImage}
        backLinkText={i18n.navigation.backToOverview}
      >
        Das gesuchte Portal konnte leider nicht gefunden werden.
      </EmptyPage>
    );
  }

  if (!portal) return null;

  const updatePortal = (body: Admin.PatchPortalBody) => {
    patchPortal(portal.id, body)
      .then((res) => (mutate(res, false), dispatch(showSuccessNotification())))
      .catch((error: unknown) => dispatch(showErrorNotification(error)));
  };

  return (
    <Page
      title={portal.name}
      backLinkText={i18n.navigation.backToOverview}
      shortInfo={
        <>
          <ShortInfo title="ID">{portal.id}</ShortInfo>
          <ShortInfo title="Easy Channel Quelle">
            {portal.easy_channel.name} ({portal.easy_channel.id})
          </ShortInfo>
        </>
      }
      tabs={[
        { to: "../general", text: "Allgemein" },
        { to: "../properties", text: "Unterkünfte" },
        { to: "../groups", text: "Gruppen" },
        { to: "../widgets", text: "Widgets" },
      ]}
    >
      <Routes>
        <Route
          path="general"
          element={
            <Panel title="Portal">
              <Form<Admin.PatchPortalBody>
                defaultValues={portal}
                onSubmit={updatePortal}
              >
                {({ register, formState: { errors } }) => (
                  <>
                    <FormField label="Name" error={errors.name}>
                      {({ labelId, required, isInvalid }) => (
                        <Input
                          id={labelId}
                          placeholder="Einen Namen vergeben"
                          {...register("name", {
                            required,
                          })}
                          isInvalid={isInvalid}
                        />
                      )}
                    </FormField>
                    <FormField
                      label="Beschreibung"
                      helpText="Die Beschreibung sollte aussagekräftig sein und den Verwendungszweck des Portals widerspiegeln."
                    >
                      {({ labelId }) => (
                        <Textarea
                          id={labelId}
                          rows={3}
                          placeholder="Eine Beschreibung hinzufügen"
                          {...register("description")}
                        />
                      )}
                    </FormField>
                  </>
                )}
              </Form>
            </Panel>
          }
        />
        <Route
          path="properties"
          element={<PortalProperties portal={portal} />}
        />
        <Route path="groups" element={<PortalGroups portalId={portal.id} />} />
        <Route
          path="widgets"
          element={<PortalWidgets portalId={portal.id} />}
        />
        <Route path="*" element={<Navigate to="general" replace />} />
      </Routes>
    </Page>
  );
};

export default PortalSettings;
