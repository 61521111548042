import { UseToggleProps } from "../../hooks/useToggle";
import { HBK } from "../../http/dashboardApi";
import { AddButton } from "../../ui/Button";
import Modal from "../../ui/Modal";
import CreateWidgetFormFields from "./CreateWidgetFormFields";

interface Props {
  modal: UseToggleProps;
  isPropertyWidget?: boolean;
  activeTypes: HBK.Widget.Type[] | undefined;
  onSubmit: (body: { type: HBK.Widget.Type }) => void;
}

const CreateWidgetModal = ({
  modal,
  isPropertyWidget = false,
  activeTypes,
  onSubmit,
}: Props) => {
  return (
    <>
      <AddButton buttonProps={{ onClick: modal.open }}>Neues Widget</AddButton>
      {modal.isOpen && (
        <Modal<{ type: HBK.Widget.Type }>
          title="Neues Widget"
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={onSubmit}
        >
          {(props) => (
            <CreateWidgetFormFields
              {...props}
              activeTypes={activeTypes}
              isPropertyWidget={isPropertyWidget}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default CreateWidgetModal;
