import classNames from "classnames";
import { JSX } from "react";
import inputStyles from "./Input.module.css";

type Props = JSX.IntrinsicElements["select"] & {
  isInvalid?: boolean;
};

const Select = ({ isInvalid, ...props }: Props) => (
  <select
    {...props}
    className={classNames(inputStyles.input, props.className, {
      [inputStyles.invalid]: isInvalid,
    })}
  />
);

export default Select;
