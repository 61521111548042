import classNames from "classnames";
import { JSX } from "react";
import styles from "./Input.module.css";

export type Props = JSX.IntrinsicElements["input"] & {
  isInvalid?: boolean;
};

const Input = ({ isInvalid, ...props }: Props) => {
  const { type = "text" } = props;
  return (
    <input
      {...props}
      type={type}
      className={classNames(styles.input, props.className, {
        [styles.invalid]: isInvalid,
      })}
    />
  );
};

export default Input;
