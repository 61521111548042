import { use } from "react";
import { Link, useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import useToggle from "../../hooks/useToggle";
import {
  deleteWidget,
  postPortalWidgets,
  usePortalWidgets,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import i18n, { getWidgetLayout } from "../../i18n";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import DateTimeField from "../../ui/DateTimeField";
import Table from "../../ui/Table";
import CreateWidgetModal from "../widget/CreateWidgetModal";

interface Props {
  portalId: string;
}

const PortalWidgets = ({ portalId }: Props) => {
  const modal = useToggle();
  const dispatch = use(NotificationContext);
  const navigate = useNavigate();
  const filters = useListFilters();
  const { data, isValidating, mutate } = usePortalWidgets(
    portalId,
    filters.state,
  );

  const canWrite = usePermissions(HBK.Permission.AdminPortalWrite);

  return (
    <Table
      head={
        <tr>
          <th>Widget</th>
          <th>Layout</th>
          <th>Eigene Farben</th>
          <th>Erstellt am</th>
          <th />
        </tr>
      }
      description={
        <>
          Widgets, welche diesem Portal zugeordnet sind.
          {canWrite && (
            <CreateWidgetModal
              modal={modal}
              activeTypes={data?.widgets.map((i) => i.type)}
              onSubmit={(body) => {
                postPortalWidgets(portalId, {
                  type: body.type,
                })
                  .then((w) => {
                    modal.close();
                    navigate(`/admin/widgets/${w.id}`);
                    dispatch(showSuccessNotification());
                  })
                  .catch((error: unknown) =>
                    dispatch(showErrorNotification(error)),
                  );
              }}
            />
          )}
        </>
      }
      isValidating={isValidating}
      total={data?.total ?? 0}
      filters={filters}
      body={({ styles }) =>
        data?.widgets.map((w) => {
          return (
            <tr key={w.id}>
              <td>
                <Link to={`/admin/widgets/${w.id}`}>
                  {i18n.widget.type[w.type]}
                </Link>
              </td>
              <td>{getWidgetLayout(w.type, w.settings.layout)}</td>
              <td>{i18n.boolean(!w.default_colors)}</td>
              <td>
                <DateTimeField dateTime={w.created_at} />
              </td>
              <td className={styles.alignRight}>
                <Confirm
                  title={`${i18n.widget.type[w.type]} löschen`}
                  description={
                    <p>
                      Möchten Sie das {i18n.widget.type[w.type]} wirklich
                      löschen?
                    </p>
                  }
                  onConfirm={() =>
                    deleteWidget(w.id).then(
                      () => (mutate(), filters.setPage(1)),
                    )
                  }
                >
                  {({ open }) => (
                    <Controls>
                      <Controls.Group>
                        <Controls.Link to={`/admin/widgets/${w.id}`}>
                          Zeige Details
                        </Controls.Link>
                        <Controls.Link to={`/admin/widgets/${w.id}/edit`}>
                          Bearbeiten
                        </Controls.Link>
                      </Controls.Group>
                      <Controls.Group>
                        <Controls.Delete onClick={open} disabled={!canWrite} />
                      </Controls.Group>
                    </Controls>
                  )}
                </Confirm>
              </td>
            </tr>
          );
        }) ?? []
      }
    />
  );
};

export default PortalWidgets;
