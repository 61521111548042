import { PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router";
import Logo from "../assets/logo.svg";
import Hamburger from "./Hamburger";
import styles from "./Header.module.css";
import UserMenu from "./UserMenu";

type Props = PropsWithChildren<{
  heading?: string;
  menu?: ReactNode;
}>;

const Header = ({ heading, menu, children }: Props) => (
  <header className={styles.header}>
    <Link to="../" className={styles.logo}>
      <img className={styles.img} src={Logo} alt="HGV Booking Logo" />
    </Link>
    {menu}
    {heading && <div className={styles.heading}>{heading}</div>}
    {children}
    <div className={styles.menu}>
      <Hamburger />
      <UserMenu />
    </div>
  </header>
);

export default Header;
