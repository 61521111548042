import { Navigate, Route, Routes } from "react-router";
import Invitations from "./Invitations";
import Properties from "./domain/property/Properties";
import Property from "./domain/property/Property";
import AppLayout from "./layouts/AppLayout";
import Header from "./layouts/Header";
import PropertyMenu from "./layouts/PropertyMenu";
import PropertySidebar from "./layouts/PropertySidebar";
import PropertyRedirect from "./pages/PropertyRedirect";

const DashboardApp = () => {
  return (
    <AppLayout
      header={
        <Header
          menu={
            <Routes>
              <Route path="properties/:propertyId">
                <Route index path="*" element={<PropertyMenu />} />
              </Route>
            </Routes>
          }
        />
      }
      sidebar={
        <Routes>
          <Route path="/properties/:propertyId">
            <Route index path="*" element={<PropertySidebar />} />
          </Route>
        </Routes>
      }
      main={
        <Routes>
          <Route index element={<PropertyRedirect />} />
          <Route path="invitations">
            <Route index element={<Invitations />} />
          </Route>
          <Route path="properties">
            <Route index element={<Properties />} />
            <Route path=":propertyId">
              <Route index element={<Navigate to="details" replace />} />

              <Route path="*" element={<Property />} />
            </Route>
          </Route>
        </Routes>
      }
    />
  );
};

export default DashboardApp;
