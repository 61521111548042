import classNames from "classnames";
import { Children, PropsWithChildren, ReactElement } from "react";
import { NavLink, To } from "react-router";
import { Glyph } from "../types";
import Icon from "../ui/Icon";
import { toToString } from "../utils";
import styles from "./Nav.module.css";

type NavProps = PropsWithChildren;

const Nav = ({ children }: NavProps) => (
  <nav className={styles.nav}>
    <NavList>{children}</NavList>
  </nav>
);

const NavList = ({ children }: PropsWithChildren) => (
  <ul className={styles.ul}>{children}</ul>
);

interface NavItemProps {
  text: string;
  icon?: Glyph;
  to: To;
  children?: ReactElement<NavItemProps>[] | ReactElement<NavItemProps>;
}

const NavItem = ({ text, icon, to, children }: NavItemProps) => {
  return (
    <li className={styles.li}>
      <NavLink
        to={to}
        // TODO: Fix isActive. Starting with React Router 7, isActive no longer
        // works with tabs.
        className={({ isActive }) =>
          classNames(styles.link, { [styles.linkActive]: isActive })
        }
      >
        {icon && <Icon glyph={icon} className={styles.linkIcon} />}
        {text}
      </NavLink>
      {children && (
        <NavList>
          {Children.map(children, (child) => ({
            ...child,
            props: {
              ...child.props,
              to: `${toToString(to)}/${toToString(child.props.to)}`,
            },
          }))}
        </NavList>
      )}
    </li>
  );
};

Nav.Item = NavItem;

export default Nav;
