import { Radio, RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { use, useState } from "react";
import { useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { HBK, postPropertyReservationPolicy } from "../../http/dashboardApi";
import Form, { FormChildrenProps } from "../../ui/Form";
import FormField from "../../ui/FormField";
import Icon from "../../ui/Icon";
import SvgCheckCircle from "../../ui/icon/CheckCircle.svg?react";
import Panel from "../../ui/Panel";
import styles from "./CreateReservationPolicy.module.css";
import ReservationPolicyFormFields from "./ReservationPolicyFormFields";

const CreateReservationPolicy = () => {
  const propertyId = usePropertyIdParam();
  const dispatch = use(NotificationContext);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<Template>(defaultTemplate);

  return (
    <Panel
      title="Reservierungsbedingung erstellen"
      description="Wählen Sie eine Vorlage aus oder legen Sie Ihre individuellen Reservierungsbedingungen fest."
    >
      <Form<HBK.ReservationPolicyBody>
        defaultValues={templates[defaultTemplate]}
        onSubmit={(values) =>
          postPropertyReservationPolicy(propertyId, values)
            .then((res) => {
              navigate(`../${res.id}`);
              dispatch(
                showSuccessNotification(
                  "Die Reservierungsbedingung wurde erfolgreich erstellt.",
                ),
              );
            })
            .catch((error: unknown) => dispatch(showErrorNotification(error)))
        }
      >
        {(props: FormChildrenProps<HBK.ReservationPolicyBody>) => {
          const { reset } = props;
          return (
            <>
              <RadioGroup
                className={styles.templates}
                value={selected}
                onChange={(t) => {
                  setSelected(t);
                  reset(templates[t]);
                }}
              >
                {Object.entries(templates).map(([template, { name }]) => (
                  <Radio
                    key={template}
                    value={template}
                    className={({ checked }) =>
                      classNames(styles.template, {
                        [styles.checked]: checked,
                      })
                    }
                  >
                    {template === "custom" ? "Benutzerdefiniert" : name}
                    <Icon glyph={SvgCheckCircle} className={styles.icon} />
                  </Radio>
                ))}
              </RadioGroup>

              <FormField
                label="Vorschau"
                direction="column"
                rightColumn={{ className: styles.preview }}
              >
                <ReservationPolicyFormFields
                  {...props}
                  isReadOnly={selected !== "custom"}
                />
              </FormField>
            </>
          );
        }}
      </Form>
    </Panel>
  );
};

type Template =
  | "standard"
  | "nonRefundable"
  | "creditCardAsSecurity"
  | "noSecurity"
  | "custom";

const defaultTemplate: Template = "standard";

const standardCancellationPolicies: HBK.CancellationPolicy[] = [
  { start_day: null, end_day: 31, fee_percentage: 30 },
  { start_day: 30, end_day: 15, fee_percentage: 40 },
  { start_day: 14, end_day: 8, fee_percentage: 60 },
  { start_day: 7, end_day: 0, fee_percentage: 70 },
];

const templates: Record<Template, HBK.ReservationPolicyBody> = {
  standard: {
    name: "Standard",
    security: "deposit",
    deposit_percentage: 30,
    credit_card_allowed: true,
    bank_transfer_allowed: true,
    bank_transfer_days_before_arrival: 0,
    cancellation_policies: standardCancellationPolicies,
  },
  nonRefundable: {
    name: "Nicht rückerstattbar",
    security: "deposit",
    deposit_percentage: 100,
    credit_card_allowed: true,
    bank_transfer_allowed: true,
    bank_transfer_days_before_arrival: 0,
    cancellation_policies: [
      { start_day: null, end_day: 0, fee_percentage: 100 },
    ],
  },
  creditCardAsSecurity: {
    name: "Kreditkarte als Sicherheit",
    security: "credit_card_required",
    credit_card_allowed: false,
    bank_transfer_allowed: false,
    bank_transfer_days_before_arrival: 0,
    cancellation_policies: standardCancellationPolicies,
  },
  noSecurity: {
    name: "Keine Sicherheit",
    security: "no_credit_card_required",
    credit_card_allowed: false,
    bank_transfer_allowed: false,
    bank_transfer_days_before_arrival: 0,
    cancellation_policies: standardCancellationPolicies,
  },
  custom: {
    name: "",
    security: "deposit",
    deposit_percentage: 30,
    credit_card_allowed: false,
    bank_transfer_allowed: false,
    bank_transfer_days_before_arrival: 0,
    cancellation_policies: [
      { start_day: null, end_day: 0, fee_percentage: 30 },
    ],
  },
};

export default CreateReservationPolicy;
