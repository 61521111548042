import { Controller, UseFormReturn } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import InlineFormField from "../../ui/InlineFormField";
import Input from "../../ui/Input";
import Radio from "../../ui/Radio";
import Select from "../../ui/Select";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { range } from "../../utils";
import CancellationPolicies from "./CancellationPolicies";
import styles from "./ReservationPolicyFormFields.module.css";

type Props = UseFormReturn<HBK.ReservationPolicyBody> & {
  isReadOnly?: boolean;
};

const daysBeforeArrival = range(0, 10);

const ReservationPolicyFormFields = (formProps: Props) => {
  const {
    isReadOnly = false,
    register,
    watch,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = formProps;

  const [
    creditCardAllowed,
    bankTransferAllowed,
    security,
    cancellationPolicies,
  ] = watch([
    "credit_card_allowed",
    "bank_transfer_allowed",
    "security",
    "cancellation_policies",
  ]);

  return (
    <>
      <FormField label="Name" error={errors.name}>
        {({ labelId, required, isInvalid }) => (
          <Input
            id={labelId}
            type="text"
            placeholder="30&nbsp;% Angeld"
            disabled={isReadOnly}
            isInvalid={isInvalid}
            {...register("name", {
              required,
            })}
          />
        )}
      </FormField>
      <FormField label="Sicherheit" error={errors.security}>
        {({ required, isInvalid }) =>
          HBK.reservationSecurity.map((rs) => (
            <Controller
              key={rs}
              name="security"
              control={control}
              rules={{ required }}
              render={({ field }) => (
                <Radio
                  {...field}
                  label={i18n.reservationPolicy.type[rs]}
                  value={rs}
                  checked={rs === security}
                  description={i18n.reservationPolicy.typeDescription[rs]}
                  isInvalid={isInvalid}
                  disabled={isReadOnly}
                  onChange={(e) => {
                    field.onChange(e);
                    e.target.value === "deposit" &&
                      setValue(
                        "deposit_percentage",
                        cancellationPolicies[0]?.fee_percentage,
                      );
                  }}
                />
              )}
            />
          ))
        }
      </FormField>
      {security === "deposit" && (
        <FormField label="Angeld">
          <FormField
            label="Prozentsatz"
            helpText="Die Zahl muss zwischen 5&nbsp;% und 100&nbsp;% liegen und darf kein Komma enthalten."
            error={errors.deposit_percentage}
            direction="column"
          >
            {({ labelId, required }) => (
              <Input
                id={labelId}
                type="number"
                disabled={isReadOnly}
                {...register("deposit_percentage", {
                  required,
                  min: 5,
                  max: 100,
                  valueAsNumber: true,
                })}
                inputMode="numeric"
              />
            )}
          </FormField>
          <FormField
            label="Zahlungsart"
            direction="column"
            error={errors.credit_card_allowed ?? errors.bank_transfer_allowed}
          >
            <div className={styles.paymentMethods}>
              <InlineFormField label="Kreditkarte" alignItemsRight={true}>
                {({ labelId }) => (
                  <Controller
                    name="credit_card_allowed"
                    control={control}
                    defaultValue={false}
                    rules={{
                      validate: (v) =>
                        v ||
                        bankTransferAllowed ||
                        i18n.errors.paymentMethodMin,
                      deps: "bank_transfer_allowed",
                    }}
                    render={({ field }) => (
                      <ToggleSwitch
                        {...field}
                        labelId={labelId}
                        checked={field.value}
                        disabled={isReadOnly}
                        onChange={(e) => {
                          field.onChange(e);
                          resetField("bank_transfer_days_before_arrival", {
                            defaultValue: 0,
                          });
                        }}
                      />
                    )}
                  />
                )}
              </InlineFormField>
              <div className={styles.bankTransfer}>
                <InlineFormField label="Banküberweisung" alignItemsRight={true}>
                  {({ labelId }) => (
                    <Controller
                      name="bank_transfer_allowed"
                      control={control}
                      defaultValue={false}
                      rules={{
                        validate: (v) =>
                          v ||
                          creditCardAllowed ||
                          i18n.errors.paymentMethodMin,
                        deps: "credit_card_allowed",
                      }}
                      render={({ field }) => (
                        <ToggleSwitch
                          {...field}
                          labelId={labelId}
                          checked={field.value}
                          disabled={isReadOnly}
                        />
                      )}
                    />
                  )}
                </InlineFormField>
                {bankTransferAllowed && (
                  <InlineFormField
                    label="bis zu"
                    text="vor Anreise."
                    className={styles.daysBeforeArrival}
                    error={errors.bank_transfer_days_before_arrival}
                    helpText={
                      !creditCardAllowed && (
                        <>
                          Bitte aktivieren Sie Zahlung mittels{" "}
                          <strong>Kreditkarte</strong>, um diese Einstellung
                          freizuschalten.
                        </>
                      )
                    }
                  >
                    {({ labelId, required }) => (
                      <Select
                        id={labelId}
                        {...register("bank_transfer_days_before_arrival", {
                          required,
                          valueAsNumber: true,
                        })}
                        className={styles.select}
                        disabled={!creditCardAllowed || isReadOnly}
                      >
                        {daysBeforeArrival.map((age) => (
                          <option key={age} value={age}>
                            {age === 1 ? "1 Tag" : `${age} Tage`}
                          </option>
                        ))}
                      </Select>
                    )}
                  </InlineFormField>
                )}
              </div>
            </div>
          </FormField>
        </FormField>
      )}
      <CancellationPolicies {...formProps} />
    </>
  );
};

export default ReservationPolicyFormFields;
