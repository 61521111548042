import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Combobox as HeadlessCombobox,
  Transition,
} from "@headlessui/react";
import classNames from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { SWRResponse } from "swr";
import useSearchFilter from "../hooks/useSearchFilter";
import { Searchable } from "../types";
import styles from "./Combobox.module.css";
import Icon from "./Icon";
import inputStyles from "./Input.module.css";
import SvgUnfoldMore from "./icon/UnfoldMore.svg?react";

interface BaseProps<T> {
  labelId?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isValidating?: boolean;
  displayValue: (value: T) => string;
  displayOption?: (value: T) => ReactNode;
  onChange: (value: T | undefined) => void;
  value?: T | undefined;
}

interface ComboboxProps<T> extends BaseProps<T> {
  items: T[];
  onInputChange: (query: string) => void;
}

const Combobox = <T extends object>({
  labelId,
  value,
  items,
  onChange,
  onInputChange,
  isDisabled = false,
  isValidating,
  placeholder = "Auswählen…",
  displayValue,
  displayOption,
}: ComboboxProps<T>) => {
  const [query, setQuery] = useState("");

  useEffect(() => onInputChange(query), [query]);

  return (
    <HeadlessCombobox disabled={isDisabled} value={value} onChange={onChange}>
      <div className={styles.input}>
        {/* TODO: remove button wrapper after this https://github.com/tailwindlabs/headlessui/discussions/1236 */}
        <ComboboxButton as="div">
          <ComboboxInput
            id={labelId}
            className={classNames(inputStyles.input, {
              [styles.disabled]: isDisabled,
            })}
            autoComplete="off"
            placeholder={placeholder}
            onChange={(e) => setQuery(e.target.value)}
            displayValue={(item: T | undefined) =>
              item ? displayValue(item) : ""
            }
          />
        </ComboboxButton>
        <ComboboxButton
          className={classNames(styles.button, {
            [styles.disabled]: isDisabled,
          })}
        >
          <Icon glyph={SvgUnfoldMore} />
        </ComboboxButton>
      </div>
      <Transition
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setQuery("")}
      >
        <ComboboxOptions
          modal={false}
          anchor="bottom"
          className={styles.options}
        >
          {items.length === 0 && query.length > 0 && !isValidating ? (
            <div className={styles.option}>
              Der Suchbegriff konnte leider nicht gefunden werden.
            </div>
          ) : items.length === 0 && query.length === 0 ? (
            <div className={styles.option}>
              Geben Sie einen Suchbegriff ein.
            </div>
          ) : (
            items.map((item, i) => (
              <ComboboxOption
                key={i}
                value={item}
                className={({ focus }) =>
                  classNames(styles.option, {
                    [styles.focused]: focus,
                  })
                }
              >
                {displayOption !== undefined
                  ? displayOption(item)
                  : displayValue(item)}
              </ComboboxOption>
            ))
          )}
        </ComboboxOptions>
      </Transition>
    </HeadlessCombobox>
  );
};

interface AsyncProps<T> extends BaseProps<T> {
  fetcher: (s: Searchable) => SWRResponse<T[]>;
}

const Async = <T extends object>(props: AsyncProps<T>) => {
  const filter = useSearchFilter();
  const { fetcher, ...rest } = props;
  const { data, isValidating } = fetcher({
    query: filter.query,
    signal: filter.signal,
  });

  return (
    <Combobox
      {...rest}
      items={data ?? []}
      isValidating={isValidating}
      onInputChange={filter.setSearchTerm}
    />
  );
};

Combobox.Async = Async;

export default Combobox;
