import { use } from "react";
import { useParams } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import {
  Admin,
  patchAlpineBitsPartner,
  useAlpineBitsPartner,
} from "../../http/adminApi";
import Page from "../../pages/Page";
import Form from "../../ui/Form";
import Panel from "../../ui/Panel";
import ShortInfo from "../../ui/ShortInfo";
import AlpineBitsPartnerFormFields from "./AlpineBitsPartnerFormFields";

const AlpineBitsPartnerSettings = () => {
  const { partnerId } = useParams();
  const { data: partner, mutate } = useAlpineBitsPartner(partnerId);
  const dispatch = use(NotificationContext);

  if (!partner) return null;

  return (
    <Page
      title={partner.name}
      shortInfo={<ShortInfo title="ID">{partner.id}</ShortInfo>}
    >
      <Panel title="Allgemein">
        <Form<Admin.AlpineBitsPartnerBody>
          defaultValues={partner}
          onSubmit={(values) =>
            patchAlpineBitsPartner(partner.id, values)
              .then((partner) => {
                mutate(partner);
                dispatch(showSuccessNotification());
              })
              .catch((error: unknown) => {
                dispatch(showErrorNotification(error));
              })
          }
        >
          {(props) => <AlpineBitsPartnerFormFields {...props} />}
        </Form>
      </Panel>
    </Page>
  );
};

export default AlpineBitsPartnerSettings;
