import { use } from "react";
import { Link, useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../context/notificationContext";
import useListFilters from "../../../hooks/useListFilters";
import { importEasyChannelHotels, useProperties } from "../../../http/adminApi";
import i18n from "../../../i18n";
import Page from "../../../pages/Page";
import Button, { AddButton } from "../../../ui/Button";
import Controls from "../../../ui/Controls";
import Table from "../../../ui/Table";
import SvgSync from "../../../ui/icon/Sync.svg?react";

const AdminProperties = () => {
  const navigate = useNavigate();
  const filters = useListFilters();
  const dispatch = use(NotificationContext);
  const { data, isValidating } = useProperties(filters.state);

  return (
    <Page
      title="Unterkünfte"
      description="Unterkünfte werden täglich von Easy Channel synchronisiert."
      controls={
        <>
          <Button
            layout="hollow"
            glyph={SvgSync}
            buttonProps={{
              title: "Unterkünfte synchronisieren",
              onClick: () => {
                importEasyChannelHotels(98)
                  .then(() => {
                    dispatch(
                      showSuccessNotification(
                        "Der Auftrag wurde in die Warteschlange aufgenommen.",
                      ),
                    );
                  })
                  .catch((error: unknown) =>
                    dispatch(showErrorNotification(error)),
                  );
              },
            }}
          >
            Synchronisieren
          </Button>
          <AddButton
            buttonProps={{
              onClick: () => navigate("create"),
            }}
          >
            Neue Unterkunft
          </AddButton>
        </>
      }
    >
      <Table
        head={
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Ortschaft</th>
            <th>Kategorie</th>
            <th />
          </tr>
        }
        filters={filters}
        isValidating={isValidating}
        total={data?.total ?? 0}
        body={() =>
          data?.properties.map((p) => {
            return (
              <tr key={p.id}>
                <td>{p.id}</td>
                <td>
                  <Link to={`/dashboard/properties/${p.id}`}>{p.name}</Link>
                </td>
                <td>{p.subdivision}</td>
                <td>{i18n.property.category[p.category]}</td>
                <td>
                  <Controls>
                    <Controls.Group>
                      <Controls.Link
                        to={`/dashboard/properties/${p.id}/widgets`}
                      >
                        Widgets
                      </Controls.Link>
                      <Controls.Link
                        to={`/dashboard/properties/${p.id}/admin/portals`}
                      >
                        Portale
                      </Controls.Link>
                      <Controls.Link
                        to={`/dashboard/properties/${p.id}/channels`}
                      >
                        Channels
                      </Controls.Link>
                      <Controls.Link
                        to={`/dashboard/properties/${p.id}/admin/lts`}
                      >
                        LTS
                      </Controls.Link>
                    </Controls.Group>
                  </Controls>
                </td>
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

export default AdminProperties;
