import { useReducer } from "react";

interface State {
  page: number;
  limit: number;
}

type Action =
  | { type: "SET_PAGE"; page: number }
  | { type: "NEXT_PAGE" }
  | { type: "PREV_PAGE" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_PAGE":
      return { ...state, page: action.page || 1 };
    case "NEXT_PAGE":
      return { ...state, page: state.page + 1 };
    case "PREV_PAGE":
      if (state.page === 1) return state;
      return { ...state, page: state.page - 1 };
  }
};

export interface UseListFiltersReturn {
  state: State;
  nextPage: () => void;
  prevPage: () => void;
  setPage: (page: number) => void;
}

interface UseListFiltersOptions {
  initialState: State;
}

const useListFilters = (
  userOptions?: Partial<UseListFiltersOptions>,
): UseListFiltersReturn => {
  const initialState: State = {
    page: 1,
    limit: 20,
  };

  const options: UseListFiltersOptions = {
    initialState,
    ...userOptions,
  };
  const [state, dispatch] = useReducer(reducer, options.initialState);

  return {
    state,
    nextPage: () => dispatch({ type: "NEXT_PAGE" }),
    prevPage: () => dispatch({ type: "PREV_PAGE" }),
    setPage: (page: number) => dispatch({ type: "SET_PAGE", page }),
  };
};

export default useListFilters;
