import classNames from "classnames";
import { ReactNode } from "react";
import { UseOffsetListFiltersReturn } from "../hooks/useOffsetListFilters";
import Button from "./Button";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./Table.module.css";
import SvgArrowBack from "./icon/ArrowBack.svg?react";
import SvgArrowForward from "./icon/ArrowForward.svg?react";

interface TableCallbackProps {
  styles: typeof styles;
}

interface TableProps {
  head: ReactNode;
  body: ReactNode[] | ((p: TableCallbackProps) => ReactNode[]);
  filters: UseOffsetListFiltersReturn;
  description?: ReactNode;
  isValidating?: boolean;
  hasNextPage: boolean;
}

const OffsetTable = ({
  description,
  head,
  body,
  filters,
  isValidating,
  hasNextPage,
}: TableProps) => {
  const callbackProps: TableCallbackProps = { styles };
  const {
    state: { page },
    prevPage,
    nextPage,
  } = filters;

  const items = typeof body === "function" ? body(callbackProps) : body;

  return (
    <div>
      {description && <div className={styles.description}>{description}</div>}
      <div className={styles.table}>
        <table>
          <thead>{head}</thead>
          <tbody
            className={classNames({
              [styles.disabled]: isValidating,
            })}
          >
            {items}
          </tbody>
        </table>
        {isValidating && (
          <div className={styles.loadingSpinner}>
            <LoadingSpinner delayed={0} />
          </div>
        )}
        {!isValidating && (
          <div className={styles.summary}>
            <div>Seite {page}</div>
            <div className={styles.paginate}>
              <Button
                layout="hollow"
                size="small"
                glyph={SvgArrowBack}
                buttonProps={{
                  disabled: page === 1,
                  onClick: () => prevPage(),
                }}
              />
              <Button
                layout="hollow"
                size="small"
                glyph={SvgArrowForward}
                buttonProps={{
                  disabled: !hasNextPage,
                  onClick: () => nextPage(),
                }}
              />
            </div>
          </div>
        )}
        {items.length === 0 && !isValidating && (
          <div className={styles.noItems}>
            Es sind keine Elemente vorhanden.
          </div>
        )}
      </div>
    </div>
  );
};

export default OffsetTable;
