import { useMemo } from "react";
import { Link, useNavigate } from "react-router";
import Error from "../../assets/error.svg";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { HBK, usePropertyPayoutProfiles } from "../../http/dashboardApi";
import i18n from "../../i18n";
import EmptyPage from "../../pages/EmptyPage";
import { BackButton } from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import radioStyles from "../../ui/Radio.module.css";

export const payoutProfiles: HBK.PayoutProfile[] = [
  {
    id: "bank_account",
    description:
      "Um die Buchung geltend zu machen, muss innerhalb von 72 Stunden der Buchungsbetrag auf das angegebene Bankkonto überwiesen werden.",
    editable: false,
  },
  {
    id: "on_site",
    description: "Der Buchungsbetrag kann vor Ort bezahlt werden.",
    editable: true,
  },
];

interface SelectablePayoutProfiles extends HBK.PayoutProfile {
  selectable: boolean;
}

const getActiveProfiles = (
  profiles: HBK.PayoutProfile[],
): SelectablePayoutProfiles[] =>
  payoutProfiles.map((profile) => ({
    ...profile,
    selectable: profiles.some((w) => w.id === profile.id),
  }));

const CreatePayoutProfile = () => {
  const navigate = useNavigate();
  const propertyId = usePropertyIdParam();
  const { data } = usePropertyPayoutProfiles(propertyId);

  const activeProfiles = useMemo(
    () => getActiveProfiles(data?.profiles ?? []),
    [data],
  );

  const preselectedProfile = useMemo(
    () => activeProfiles.filter((i) => !i.selectable),
    [activeProfiles],
  );

  if (!data) return null;
  if (!preselectedProfile[0]) {
    return (
      <EmptyPage title="Zahlungsprofile bereits erstellt" image={Error}>
        Alle möglichen <Link to="../..">Zahlungsprofile</Link> wurden bereits
        erstellt.
      </EmptyPage>
    );
  }

  return (
    <Panel title="Neues Zahlungsprofil">
      <Form<{ id: HBK.PayoutProfileId }>
        defaultValues={{ id: preselectedProfile[0].id }}
        secondaryButton={<BackButton />}
        submitText="Weiter"
        onSubmit={({ id }) => {
          navigate(`../${id}`);
        }}
      >
        {({ register }) =>
          activeProfiles.map((profile) => (
            <FormField
              key={profile.id}
              label={i18n.payoutProfile[profile.id]}
              helpText={profile.description}
              disabled={profile.selectable}
            >
              {({ labelId, required }) => (
                <input
                  id={labelId}
                  type="radio"
                  disabled={profile.selectable}
                  className={radioStyles.input}
                  {...register("id", {
                    required,
                  })}
                  value={profile.id}
                />
              )}
            </FormField>
          ))
        }
      </Form>
    </Panel>
  );
};

export default CreatePayoutProfile;
