import classNames from "classnames";
import { PropsWithChildren } from "react";
import { HBK } from "../http/dashboardApi";
import i18n from "../i18n";
import { Glyph } from "../types";
import styles from "./Badge.module.css";
import Icon from "./Icon";

export type BadgeType = "success" | "danger" | "info" | "warning";

type Props = PropsWithChildren<{
  type?: BadgeType | undefined;
  className?: string;
}>;

const Badge = ({ children, type = "info", className }: Props) => {
  return (
    <span className={classNames(styles.badge, styles[type], className)}>
      {children}
    </span>
  );
};

interface BadgeIconProps extends Props {
  glyph: Glyph;
}

const BadgeIcon = ({
  children,
  type = "info",
  className,
  glyph,
}: BadgeIconProps) => (
  <Badge type={type} className={classNames(styles.badgeIcon, className)}>
    <Icon glyph={glyph} className={styles.icon} />
    {children}
  </Badge>
);

interface ListingProps {
  listingStatus: HBK.ListingStatus;
}

const Listing = ({ listingStatus }: ListingProps) => (
  <Badge type={listingStatus === "listed" ? "success" : "info"}>
    {i18n.property.listingStatus[listingStatus]}
  </Badge>
);

Badge.Listing = Listing;
Badge.Icon = BadgeIcon;

export default Badge;
