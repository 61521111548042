import { use } from "react";
import { Link } from "react-router";
import NotificationContext, {
  showErrorNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import {
  deletePortalGroup,
  postPortalGroups,
  usePortalGroups,
  useSearchGroups,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Badge from "../../ui/Badge";
import { ConfirmButton } from "../../ui/Button";
import ModalAssign from "../../ui/ModalAssign";
import Table from "../../ui/Table";
import SvgDelete from "../../ui/icon/Delete.svg?react";

interface Props {
  portalId: string;
}

const PortalGroups = ({ portalId }: Props) => {
  const filters = useListFilters();
  const { data, isValidating, mutate } = usePortalGroups(
    portalId,
    filters.state,
  );
  const canWrite = usePermissions(HBK.Permission.AdminPortalWrite);
  const dispatch = use(NotificationContext);

  return (
    <Table
      head={
        <tr>
          <th>Name</th>
          <th />
          <th />
        </tr>
      }
      description={
        <>
          Gruppen, welche diesem Portal zugeordnet sind.
          {canWrite && (
            <ModalAssign
              title="Gruppen zuweisen"
              description="Wählen Sie die Gruppen aus, die Sie zuweisen möchten."
              fetcher={useSearchGroups}
              onSubmit={(groups) =>
                postPortalGroups(
                  portalId,
                  groups.map((g) => g.id),
                )
                  .then(() => (mutate(), filters.setPage(1)))
                  .catch((error: unknown) =>
                    dispatch(showErrorNotification(error)),
                  )
              }
              displayValue={(g) => g.name}
            />
          )}
        </>
      }
      isValidating={isValidating}
      total={data?.total ?? 0}
      filters={filters}
      body={({ styles }) =>
        data?.groups.map((g) => {
          return (
            <tr key={g.id}>
              <td>
                <Link to={`/admin/groups/${g.id}`}>{g.name}</Link>
              </td>
              <td>
                ID: <Badge>{g.id}</Badge>
              </td>
              <td className={styles.alignRight}>
                <ConfirmButton
                  disabled={!canWrite}
                  glyph={SvgDelete}
                  modal={{
                    title: "Gruppe entfernen",
                    description: (
                      <p>
                        Möchten Sie <strong>{g.name}</strong> wirklich
                        entfernen?
                      </p>
                    ),
                    submitText: "Entfernen",
                  }}
                  onSubmit={() =>
                    deletePortalGroup(portalId, g.id)
                      .then(() => (mutate(), filters.setPage(1)))
                      .catch((error: unknown) =>
                        dispatch(showErrorNotification(error)),
                      )
                  }
                />
              </td>
            </tr>
          );
        }) ?? []
      }
    />
  );
};

export default PortalGroups;
