import { use } from "react";
import { Link, useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import useToggle from "../../hooks/useToggle";
import { Admin, deleteGroup, postGroup, useGroups } from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Page from "../../pages/Page";
import { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import Modal from "../../ui/Modal";
import Table from "../../ui/Table";
import GroupFormFields from "./GroupFormFields";

const Groups = () => {
  const filters = useListFilters();
  const { data, isValidating, mutate } = useGroups(filters.state);
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);
  const canWrite = usePermissions(HBK.Permission.AdminGroupWrite);

  return (
    <Page
      title="Gruppen"
      description="Organisieren Sie Unterkünfte in Gruppen, um sie in
        unterschiedlichen Portalen wiederverwenden zu können. Manche Gruppen,
        wie beispielsweise Tourismusorganisationen, aktualisieren sich
        automatisch und können nicht bearbeitet werden."
      controls={
        canWrite && (
          <AddGroup
            onSubmit={(group) =>
              postGroup(group)
                .then((res) => navigate(res.id))
                .catch((error: unknown) =>
                  dispatch(showErrorNotification(error)),
                )
            }
          />
        )
      }
    >
      <Table
        head={
          <tr>
            <th>Name</th>
            <th>Zuweisung</th>
            <th />
          </tr>
        }
        isValidating={isValidating}
        total={data?.total ?? 0}
        filters={filters}
        body={({ styles }) =>
          data?.groups.map((g) => {
            return (
              <tr key={g.id}>
                <td>
                  <Link to={g.id}>{g.name}</Link>
                </td>
                <td>{i18n.group.source[g.source]}</td>
                <td className={styles.alignRight}>
                  <Confirm
                    title="Gruppe löschen"
                    description={
                      <p>
                        Möchten Sie die Gruppe <strong>{g.name}</strong>{" "}
                        wirklich löschen?
                      </p>
                    }
                    onConfirm={() =>
                      deleteGroup(g.id).then(
                        () => (mutate(), filters.setPage(1)),
                      )
                    }
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link to={`${g.id}/general`}>
                            Allgemein
                          </Controls.Link>
                          <Controls.Link to={`${g.id}/properties`}>
                            Unterkünfte
                          </Controls.Link>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Delete
                            onClick={open}
                            title={
                              g.read_only
                                ? "Schreibgeschützte Gruppen können nicht gelöscht werden"
                                : undefined
                            }
                            disabled={!canWrite || g.read_only}
                          />
                        </Controls.Group>
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

interface AddGroupModalProps {
  onSubmit: (values: Admin.GroupBody) => void;
}

const AddGroup = ({ onSubmit }: AddGroupModalProps) => {
  const modal = useToggle();

  return (
    <>
      <AddButton
        buttonProps={{
          onClick: modal.open,
        }}
      >
        Neue Gruppe
      </AddButton>
      {modal.isOpen && (
        <Modal<{ name: string }>
          title="Gruppe erstellen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={(values) => {
            onSubmit(values);
            modal.close();
          }}
        >
          {(props) => <GroupFormFields {...props} />}
        </Modal>
      )}
    </>
  );
};

export default Groups;
