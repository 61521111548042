import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { FocusEventHandler } from "react";
import styles from "./ToggleSwitch.module.css";

interface Props {
  labelId: string;
  checked: boolean;
  disabled?: boolean | undefined;
  title?: string;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onChange?: (checked: boolean) => void;
}

const ToggleSwitch = ({
  labelId,
  checked,
  disabled = false,
  title,
  onBlur,
  onChange = () => {},
}: Props) => {
  return (
    <Switch
      checked={checked}
      onBlur={onBlur}
      onChange={onChange}
      className={classNames(styles.switch, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      title={title}
    >
      <span
        id={labelId}
        className={classNames(styles.toggle, {
          [styles.checked]: checked,
        })}
      />
    </Switch>
  );
};

export default ToggleSwitch;
