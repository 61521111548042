import { useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router";
import usePermissions from "../../hooks/usePermissions";
import useToggle from "../../hooks/useToggle";
import { HBK } from "../../http/dashboardApi";
import Badge from "../../ui/Badge";
import { AddButton, ConfirmButton } from "../../ui/Button";
import Card from "../../ui/Card";
import Combobox from "../../ui/Combobox";
import FormField from "../../ui/FormField";
import Modal from "../../ui/Modal";
import Panel from "../../ui/Panel";
import SvgDelete from "../../ui/icon/Delete.svg?react";
import SVGError from "../../ui/icon/Error.svg?react";
import SvgQuote from "../../ui/icon/Quote.svg?react";
import { parseBoardNameFromAlpineBitsId } from "./utils";

interface Props {
  config: HBK.Tomas.ConfigRatePlans;
  onSubmit: (mapping: HBK.Tomas.MappingRatePlans) => void;
  onDelete: (tomasId: string) => void;
}

const TomasRatePlans = ({ config, onSubmit, onDelete }: Props) => {
  const canWrite = usePermissions(HBK.Permission.AdminMetasearchWrite);

  const ratePlans = Object.values(config.rate_plans);

  return (
    <Panel
      title="Ratenpläne"
      controls={
        config.not_mapped_rate_plans.length > 0 &&
        canWrite && (
          <AddConfiguration
            config={config}
            onSubmit={(rp) => {
              onSubmit({ ...config.rate_plans, [rp.tomas_id]: rp });
            }}
          />
        )
      }
    >
      {ratePlans.map((plan) => (
        <Card
          key={plan.tomas_id}
          title={plan.tomas_id}
          glyph={SvgQuote}
          link={plan.base_plan ? plan.tomas_id : undefined}
          controls={
            canWrite && (
              <ConfirmButton
                glyph={SvgDelete}
                modal={{
                  title: "Ratenplan löschen",
                  description: (
                    <p>
                      Möchten Sie <strong>{plan.tomas_id}</strong> wirklich
                      löschen?
                    </p>
                  ),
                  submitText: "Löschen",
                }}
                onSubmit={() => onDelete(plan.tomas_id)}
              />
            )
          }
        >
          {plan.base_plan ? (
            parseBoardNameFromAlpineBitsId(plan.base_plan.id)
          ) : (
            <Badge.Icon type="danger" glyph={SVGError}>
              Fehlerhafte Konfiguration, diesen Ratenplan löschen und neu
              konfigurieren.
            </Badge.Icon>
          )}
        </Card>
      ))}
    </Panel>
  );
};

interface AddConfigurationProps {
  config: HBK.Tomas.ConfigRatePlans;
  onSubmit: (values: HBK.Tomas.RatePlans) => void;
}

const AddConfiguration = ({ config, onSubmit }: AddConfigurationProps) => {
  const modal = useToggle();
  const navigate = useNavigate();

  return (
    <>
      <AddButton
        buttonProps={{
          onClick: modal.open,
        }}
      >
        Neue Konfiguration
      </AddButton>
      {modal.isOpen && (
        <Modal<{
          basePlan: HBK.Tomas.Inventory;
          tomasPlan: HBK.Tomas.Inventory;
        }>
          title="Konfiguration erstellen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={(values) => {
            onSubmit({
              tomas_id: values.tomasPlan.id,
              base_plan: values.basePlan,
              rate_plans: [],
            });
            navigate(values.tomasPlan.id);
            modal.close();
          }}
        >
          {({ control }) => (
            <>
              <FormField label="Tomas Ratenplan" direction="column">
                {({ labelId, required }) => (
                  <Controller
                    name="tomasPlan"
                    control={control}
                    rules={{ required }}
                    render={({ field }) => (
                      <RatePlanCombobox
                        labelId={labelId}
                        value={field.value}
                        ratePlans={config.not_mapped_rate_plans}
                        onChange={field.onChange}
                        displayValue={(rp) => rp.title}
                        placeholder="Tomas Ratenplan suchen…"
                      />
                    )}
                  />
                )}
              </FormField>
              <FormField
                label="Basis-Ratenplan"
                direction="column"
                description="Ratenplan, auf dem alle weiteren Ratenpläne im nächsten Schritt aufbauen."
              >
                {({ labelId, required }) => (
                  <Controller
                    name="basePlan"
                    control={control}
                    rules={{ required }}
                    render={({ field }) => (
                      <RatePlanCombobox
                        labelId={labelId}
                        value={field.value}
                        ratePlans={config.alpine_bits_rate_plans}
                        onChange={field.onChange}
                        displayValue={(rp) => `${rp.title} (${rp.id})`}
                        placeholder="Basisplan suchen…"
                      />
                    )}
                  />
                )}
              </FormField>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default TomasRatePlans;

interface RatePlanComboboxProps {
  labelId: string;
  ratePlans: HBK.Tomas.Inventory[];
  value?: HBK.Tomas.Inventory;
  placeholder: string;
  onChange: (rp: HBK.Tomas.Inventory | undefined) => void;
  displayValue: (rp: HBK.Tomas.Inventory) => string;
}

const RatePlanCombobox = ({
  labelId,
  value,
  ratePlans,
  placeholder,
  displayValue,
  onChange,
}: RatePlanComboboxProps) => {
  const { filtered, onSearch } = useSearch(ratePlans);

  return (
    <Combobox
      labelId={labelId}
      items={[...filtered.sort((a, b) => (a.title < b.title ? -1 : 1))]}
      onChange={onChange}
      value={value}
      displayValue={displayValue}
      onInputChange={onSearch}
      placeholder={placeholder}
    />
  );
};

const useSearch = (items: HBK.Tomas.Inventory[]) => {
  const [filtered, setFiltered] = useState(items);

  const onSearch = (query: string) =>
    setFiltered(
      query === ""
        ? items
        : items.filter((item) =>
            item.title.toLowerCase().includes(query.toLowerCase()),
          ),
    );

  return {
    filtered,
    onSearch,
  };
};
