import { use, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import { HBK, putChannel, useChannels } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Page from "../../pages/Page";
import { Glyph } from "../../types";
import { BackButton } from "../../ui/Button";
import Card from "../../ui/Card";
import Form from "../../ui/Form";
import radioStyles from "../../ui/Radio.module.css";
import SvgAlpineBits from "../../ui/icon/AlpineBits.svg?react";
import SvgCloudSync from "../../ui/icon/CloudSync.svg?react";

interface Props {
  propertyId: number;
}

interface Channel {
  id: string;
  name: string;
  protocol: HBK.Channel.Protocol;
  glyph: Glyph;
}

const channelsInfo: Channel[] = [
  {
    id: "ddc26f40-ef7c-4e36-9555-0fd253033f60",
    name: "Google",
    protocol: "channex",
    glyph: SvgCloudSync,
  },
  {
    id: "3ca29ae5-834f-4552-a497-b4da3db3b020",
    name: "IDM Test",
    protocol: "alpine_bits",
    glyph: SvgAlpineBits,
  },
  {
    id: "9b95ede1-23ab-40b2-85e5-f6019d1e7a0a",
    name: "Schneemenschen",
    protocol: "alpine_bits",
    glyph: SvgAlpineBits,
  },
  {
    id: "",
    name: "TripAdvisor, Trivago, Kayak",
    protocol: "tomas",
    glyph: SvgCloudSync,
  },
];

interface SelectableChannel extends Channel {
  selectable: boolean;
}

const getSelectableChannel = (activeChannels: string[]): SelectableChannel[] =>
  channelsInfo.map((info) => ({
    ...info,
    selectable: activeChannels.some((w) => w === info.name),
  }));

const CreateChannel = ({ propertyId }: Props) => {
  const dispatch = use(NotificationContext);
  const filters = useListFilters();
  const navigate = useNavigate();
  const [protocol, setProtocol] = useState<HBK.Channel.Protocol>("channex");

  const { data } = useChannels(propertyId, filters.state);

  const selectableChannel = useMemo(
    () => getSelectableChannel(data?.channels.map((i) => i.name) ?? []),
    [data],
  );

  const preselectedChannel = useMemo(
    () => selectableChannel.filter((i) => !i.selectable),
    [selectableChannel],
  );

  useEffect(
    () => preselectedChannel[0] && setProtocol(preselectedChannel[0]?.protocol),
    [preselectedChannel],
  );

  if (!data) return null;

  const id = preselectedChannel[0]?.id;

  return (
    <Page
      title="Channel erstellen"
      backLinkText={i18n.navigation.backToOverview}
    >
      <Form<{ id: string }>
        defaultValues={id ? { id } : undefined}
        submitText={protocol === "tomas" ? "Weiter" : "Speichern"}
        secondaryButton={<BackButton />}
        onSubmit={(values) => {
          if (protocol === "tomas") {
            navigate("tomas");
          } else {
            putChannel(propertyId, values.id)
              .then(() => {
                navigate("..");
                dispatch(showSuccessNotification());
              })
              .catch((error: unknown) =>
                dispatch(showErrorNotification(error)),
              );
          }
        }}
      >
        {({ register }) =>
          selectableChannel.map((c, i) => (
            <Card
              key={i}
              title={c.name}
              glyph={c.glyph}
              disabled={c.selectable}
              controls={
                <input
                  type="radio"
                  disabled={c.selectable}
                  className={radioStyles.input}
                  {...register("id", {
                    required: true,
                  })}
                  onChange={() => setProtocol(c.protocol)}
                  value={c.id}
                />
              }
            >
              {i18n.channels.protocol[c.protocol]}
            </Card>
          ))
        }
      </Form>
    </Page>
  );
};

export default CreateChannel;
